import styles from "./styles.module.css"

export default function Banner({setModalHidden}) {
	return (
		<section className={styles.banner}>
			<div className={styles.image}>
				<img src={"/banner.jpg"} alt='баннер' width={700} height={500} />
			</div>
			<div className={styles.content}>
				<div className={styles.title}>Добро пожаловать в мир автомобилей Chery от официального дилера Chery МАКСИМУМ!</div>
				<div className={styles.footer}>
					Мы рады сообщить вам, что теперь вы можете легко и удобно приобрести автомобили Chery на маркетплейсе OZON. Это отличная возможность стать владельцем качественного и стильного автомобиля прямо из вашего дома!
				</div>
				<ul>
					<li className={styles.line}>Широкий модельный ряд автомобилей Chery – ваш идеальный выбор!</li>
					<li className={styles.line}>Скидки, которые вас приятно удивят!</li>
					<li className={styles.line}>Удобство приобретения – наш приоритет!</li>
				</ul>
				<div className={styles.footer}>
					Специальное предложение на 11.11!<br/>
					Только в этот день у вас есть шанс приобрести автомобили Chery с огромной скидкой на маркетплейсе OZON! Не упустите уникальную возможность обновить свой автопарк и стать обладателем надежного транспортного средства по выгодной цене.
				</div>
				<div className={styles.footer}>Следите за обновлениями и акциями, и пусть ваш новый автомобиль станет вашим верным спутником на
					дороге!</div>
				<a className={styles.button} target={"_blank"} href={"https://www.ozon.ru/seller/chery-maksimum-ofitsialnyy-onlayn-diler-1757779/avtomobili-39803/?miniapp=seller_1757779"}>
					Купить на OZON
				</a>
			</div>
		</section>
	)
}
