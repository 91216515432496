import Container from "../Container"
import styles from "./styles.module.css"

export function Footer() {
	return (
		<footer className={styles.footer}>
			<Container>
				<div className={styles.logo}>
					<img src='/logo.png' />
				</div>
				<div className={styles.title}>© 2024, CHERY MAXIMUM</div>
				<a className={styles.policy} href='https://sales.maximum-chery.ru/privacy-policy' target='_blank'>
					Политика обработки персональных данных
				</a>
				<p>
					Общество с ограниченной ответственностью «Максимум ОПТ»
					<br />
					ИНН 7804545695
					<br />
					ОГРН 1147847406596
					<br />
					Данный сайт носит информационный характер и ни при каких условиях материалы и цены размещенные на сайте не являются публичной офертой.
					<br />
					*По итогам продаж с 2020 по 2024 год по данным Аналитического Агентства Автостат .
				</p>
			</Container>
		</footer>
	)
}
