import Container from "../Container"
import styles from "./styles.module.css"

export default function Header() {
	return (
		<Container noPaddingBottom noPaddingTop>
			<header className={styles.header}>
				<div className={styles.logo}>
					<img src='/logo.png' />
				</div>
				<div className={styles.logoText}>
					<span>Чери Максимум</span>
					<br />
					<span>Официальный диллер Chery</span>
				</div>
				<div className={styles.location}>
					<div>г. Санкт-Петербург, ул. Руставели, 53</div>
					<div>Время работы: с 9:00 до 21:00</div>
				</div>
				<div>
					<a href='tel:+78125012472'>+7 (812) 501-24-72</a>
				</div>
			</header>
		</Container>
	)
}
