import styles from "./styles.module.css"

export default function Contacts() {
	return (
		<div className={styles.contacts}>
			<div className={styles.map}>
					<iframe
						src="https://yandex.ru/map-widget/v1/?um=constructor%3Ace5b542b466de0907a04a8947e6fb54e8fb3bc0da1bf99880048488bac36b794&utm_source=mapframe&z=16.8"
						width="1920" height="400" frameBorder="0" allowFullScreen="true"></iframe>
				</div>
			<div className={styles.info}>
				<div className={styles.title}>Chery Максимум</div>
				<div className={styles.description}>Официальный дилер в Санкт-Петербурге</div>
				<a href='tel:+78125012472' className={styles.phone}>
					+7 (812) 501-24-72
				</a>
				<div className={styles.time}>Время работы: с 9:00 до 21:00</div>
			</div>
		</div>
	)
}
