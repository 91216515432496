import {useEffect, useState} from "react"
import Container from "../Container"
import styles from "./styles.module.css"
import close from "./close.png"
import InputMask from "react-input-mask"

export default function Modal({modalHidden, setModalHidden}) {
	const [checkboxChecked, setCheckboxChecked] = useState(true)
	const [phone, setPhone] = useState("")
	const [isSended, setIsSended] = useState(false)

	useEffect(() => {
		setIsSended(false)
	}, [modalHidden])

	const onPhoneChange = (e) => {
		setPhone(e.target.value)
	}

	const sendRequest = () => {
		if (checkboxChecked) {
			const data = {
				name: "Ввод не предусмотрен",
				phone,
				message: "Заявка с сайта event.maximum-chery.ru/",
				model: "Нет модели",
				target: "Заявка с сайта event.maximum-chery.ru/",
				url: window.location.href,
			}

			fetch("https://maximum-auto.ru/api/leadcollector/", {
				method: "POST",
				body: JSON.stringify({
					brand: "chery",
					communication_type: "site",
					date_time: new Date(),
					dep_type: "ОПНА",
					name: "Ввод не предусмотрен",
					phone_number: phone,
					message: "Заявка с сайта event.maximum-chery.ru/",
				}),
			})
				.then((res) => {
					console.info("Заявка отправлена в ЛК")
					setIsSended(true)
				})
				.catch(console.error)

			const Comagic = window.Comagic
			try {
				Comagic.addOfflineRequest(data)
			} catch (error) {
				console.warn(`Отправка в Comagic не удалась. Причина ${error}`)
			}
		} else {
			alert("Необходимо согласиться с правилами обработки персональных данных")
		}
	}

	return (
		<div className={`${styles.modal} ${modalHidden ? styles.hidden : ""}`}>
			<div className={styles.content}>
				<img className={styles.closeButton} src={close} onClick={() => setModalHidden(true)} />
				<div className={styles.title}>{isSended ? "Успешно" : "Отправить заявку"}</div>
				{!isSended ? (
					<>
						<div className={styles.text}>
							Заполните форму и мы свяжемся с<br />
							Вами в ближайшее время.
						</div>

						{/* <input type='tel' className={styles.input} name='phone' required='' id='vigodaFormPhone' placeholder='Ваш телефон' />{" "} */}
						<InputMask mask='+7 (999) 999-99-99' value={phone} onChange={onPhoneChange}>
							{(inputProps) => <input {...inputProps} type='tel' minLength={18} placeholder='+7 (___) ___-__-__' className={`${styles.input}`} />}
						</InputMask>
						<div class='form__row'>
							<label class='form__checkbox'>
								<input
									id='checkboxNewPageVig'
									className={styles.checkbox}
									type='checkbox'
									name=''
									checked={checkboxChecked}
									onChange={(e) => setCheckboxChecked(!checkboxChecked)}
								/>
								<span>
									Я соглашаюсь с{" "}
									<a href='https://sales.maximum-chery.ru/privacy-policy.html' target='_blank'>
										правилами обработки
										<br />
										персональных данных
									</a>{" "}
									и согласен
									<br />
									получить информацию от Chery
								</span>{" "}
							</label>
						</div>
						<button type='submit' id='sendVigoda' className={styles.button} onClick={sendRequest}>
							Отправить
						</button>
					</>
				) : (
					<div className={styles.text}>
						Заявка отправлена.
						<br />
						Свяжемся с <br />
						Вами в ближайшее время.
					</div>
				)}
			</div>
		</div>
	)
}
